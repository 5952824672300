//Icons from https://lucide.dev/.
body {
  i[class*='le-icon-'] {
    width: 24px;
    height: 24px;
    display: inline-block;
    background-color: @Black;
    flex-shrink: 0;

    &.rot-180 {
      transform: rotate(180deg);
    }

    &.rot-90 {
      transform: rotate(90deg);
    }

    &.rot-270 {
      transform: rotate(270deg);
    }

    &.spinner {
      margin-left: 8px;
      vertical-align: middle;
      scale: 75%;
      animation: spin 1.5s linear infinite;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(1turn);
    }
  }

  .createIcon(@svgfilename) {
    i.le-icon-@{svgfilename} {
      mask: url('icons/@{svgfilename}.svg') no-repeat center;
      -webkit-mask: url('icons/@{svgfilename}.svg') no-repeat center;
    }
  }

  //lucide icons
  .createIcon(search);
  .createIcon(shopping-cart);
  .createIcon(menu);
  .createIcon(globe);
  .createIcon(x);
  .createIcon(user);
  .createIcon(map-pin);
  .createIcon(filter);
  .createIcon(plus);
  .createIcon(minus);
  .createIcon(chevron);
  .createIcon(plus-square);
  .createIcon(fitguide);
  .createIcon(arrow);
  .createIcon(info);
  .createIcon(file-text);
  .createIcon(package);
  .createIcon(reply);
  .createIcon(shield-check);
  .createIcon(warning);
  .createIcon(corner-up-left);
  .createIcon(check);
  .createIcon(check-small);
  .createIcon(dots);
  .createIcon(edit);
  .createIcon(trash);
  .createIcon(undo);
  .createIcon(file);
  .createIcon(excel-spreadsheet);
  .createIcon(publish);
  .createIcon(copy-to);
  .createIcon(calendar);
  .createIcon(minus-circle);
  .createIcon(rename);
  .createIcon(download);
  .createIcon(preview);
  .createIcon(party-popper);
  .createIcon(baby);
  .createIcon(home);
  .createIcon(star);
  .createIcon(timer);
  .createIcon(eye);
  .createIcon(eye-off);
  .createIcon(radio);
  .createIcon(radio-selected);
  .createIcon(bg);
  .createIcon(external-link);
  .createIcon(cut);
  .createIcon(play);
  .createIcon(car-seat);
  .createIcon(dog-crate);
  .createIcon(rooftop-tent);
  .createIcon(vector);
  .createIcon(upload);
  .createIcon(image-off);
  .createIcon(copy);
  .createIcon(chevron-first);
  .createIcon(chevron-last);
  .createIcon(arrow-long);
  .createIcon(heart);
  .createIcon(heart-fill);
  .createIcon(bookmark);
  //thule custom
  .createIcon(alert-circle);
  .createIcon(check-circle);
  .createIcon(plus-circle);
  .createIcon(x-circle);
  .createIcon(share);
}

.thule-logo::before {
  font-family: 'frg-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  content: '\e924';
  font-size: 2.4rem;
}
