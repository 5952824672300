.btn {
  font-weight: 500;

  &[class*='thule-btn-'] {
    min-height: 5.6rem;
    min-width: 16rem;
    font-size: 16px;

    &.btn-sm,
    .btn-group-sm > .btn {
      min-height: 4rem;
      min-width: 10rem;
      font-size: 14px;
    }

    &.btn-md,
    .btn-group-md > .btn {
      min-height: 4.8rem;
      min-width: 10rem;
      font-size: 16px;
    }

    &.btn-lg,
    .btn-group-lg > .btn {
      min-height: 5.6rem;
      min-width: 20rem;
      font-size: 16px;
    }
  }

  &.thule-btn-default,
  &.thule-btn-link,
  &.thule-btn-shop,
  &.thule-btn-shop-action,
  &.thule-btn-super-action {
    .button-variant(@DefaultBtnColor; @DefaultBtnBGColor; @DefaultBtnBorder; @DefaultBtnHover; @DefaultBtnColor; @DefaultBtnActiveBG; @DefaultBtnActiveColor; @DefaultBtnDisabledBG; @DefaultBtnDisabledColor; @DefaultBtnDisabledBG;);
  }

  &.thule-btn-default-bg-dark,
  &.thule-btn-link-bg-dark,
  &.thule-btn-shop-action-bg-dark {
    .button-variant(@DefaultBtnDarkColor; @DefaultBtnBGDarkColor; @DefaultBtnDarkBorder; @DefaultBtnDarkHover; @DefaultBtnDarkColor; @DefaultBtnDarkActiveBG; @DefaultBtnDarkActiveColor; @DefaultBtnDarkDisabledBG; @DefaultBtnDarkDisabledColor; @DefaultBtnDarkDisabledBG;);
  }

  &.thule-btn-secondary,
  &.thule-btn-link-secondary {
    .button-variant(@SecondaryBtnColor; @SecondarayBtnBgColor; @SecondaryBtnBorder; @SecondaryBtnHoverBG; @SecondaryBtnHoverColor; @SecondaryBtnActiveBG; @SecondaryBtnActiveColor; @SecondaryBtnDisabledBG; @SecondaryBtnDisabledColor; @SecondaryBtnDisabledBorder;);
  }

  &.thule-btn-secondary-bg-dark,
  &.thule-btn-link-secondary-bg-dark {
    .button-variant(@SecondaryBtnDarkColor; @SecondarayBtnBgDarkColor; @SecondaryBtnDarkBorder; @SecondaryBtnDarkHoverBG; @SecondaryBtnDarkHoverColor; @SecondaryBtnDarkActiveBG; @SecondaryBtnDarkActiveColor; @SecondaryBtnDarkDisabledBG; @SecondaryBtnDarkDisabledColor; @SecondaryBtnDarkDisabledBorder;);
  }

  &.thule-btn-tertiary {
    .button-variant(@TertiaryBtnColor; @TertiaryBtnBgColor; @TertiaryBtnBgColor; @TertiaryBtnHover; @TertiaryBtnColor; @TertiaryBtnBgColor; @TertiaryBtnActiveColor; @TertiaryBtnBgColor; @TertiaryBtnDisabledColor; @TertiaryBtnBgColor;);
    text-decoration: underline;
    font-weight: 400 !important;
    text-underline-offset: 4px;
  }

  &.thule-btn-tertiary-bg-dark,
  &.thule-btn-link-tertiary-bg-dark {
    .button-variant(@TertiaryBtnDarkColor; @TertiaryBtnBgDarkColor; @TertiaryBtnBgDarkColor; @TertiaryBtnDarkHover; @TertiaryBtnDarkColor; @TertiaryBtnBgDarkColor; @TertiaryBtnDarkActiveColor; @TertiaryBtnBgDarkColor; @TertiaryBtnDarkDisabledColor; @TertiaryBtnBgDarkColor;);
  }

  &.thule-btn-tertiary-bg-dark {
    text-decoration: underline;
    font-weight: 400 !important;
  }

  &.thule-btn-tertiary,
  &.thule-btn-tertiary-bg-dark {
    min-width: unset;
  }

  &.thule-btn-link {
    &:focus,
    &.focus {
      border: 1px solid @DefaultBtnBorder;
    }
  }

  &.thule-btn-link-bg-dark {
    &:focus,
    &.focus {
      border: 1px solid @DefaultBtnDarkBorder;
    }
  }

  &.thule-btn-link-secondary {
    &:focus,
    &.focus {
      border: 1px solid @SecondaryBtnBorder;
    }
  }

  &.thule-btn-link-secondary-bg-dark {
    &:focus,
    &.focus {
      border: 1px solid @SecondaryBtnDarkBorder;
    }
  }

  &.thule-btn-link-tertiary,
  &.thule-btn-link-tertiary-bg-dark {
    background-color: transparent;
    min-width: unset;
  }

  &.thule-btn-link-tertiary {
    padding-left: 0;
    padding-right: 0;
    color: @Black;

    i {
      background-color: @Black;
    }
  }

  &.thule-btn-link-tertiary-bg-dark {
    color: @White;

    i {
      background-color: @White;
      margin-left: @spacer3;
    }
  }

  &.thule-btn-link,
  &.thule-btn-link-bg-dark,
  &.thule-btn-link-secondary,
  &.thule-btn-link-secondary-bg-dark,
  &.thule-btn-link-tertiary-bg-dark {
    justify-content: space-between;
    padding-left: @spacer5;
    padding-right: @spacer5;
  }

  &.thule-btn-link,
  &.thule-btn-link-bg-dark,
  &.thule-btn-link-secondary,
  &.thule-btn-link-secondary-bg-dark,
  &.thule-btn-link-tertiary {
    display: flex;
    align-items: center;

    i {
      margin-left: @spacer5;
    }
  }

  &.thule-btn-account {
    .button-variant(@AccountBtnColor; @AccountBtnBgColor; @AccountBtnBorder; @AccountBtnBgColor; @AccountBtnColor; @AccountBtnBgColor; @AccountBtnColor; @AccountBtnBgColor; @AccountBtnColor; @AccountBtnBgColor; );
    .button-activebackground-darker(@AccountBtnBgColor);

    &.disabled {
      border: 1px solid @AccountBtnBgColor;
      opacity: 1;
    }
  }

  &.thule-btn-variation {
    .button-variant(@VariationBtnColor; @VariationBtnBgColor; @VariationBtnBgColor; @VariationBtnBgColor; @VariationBtnColor; @VariationBtnBgColor; @VariationBtnColor; @VariationBtnBgColor; @VariationBtnColor; @VariationBtnBgColor;);

    font-size: 1.4rem;
    padding: 0.1rem @spacer7;
    margin-bottom: 0.5rem;
    min-width: unset;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: @Gray10;
    &.active {
      border: 1px @Black solid !important;
      cursor: default;
      background-color: @Gray10 !important;
    }
    &:hover:not(.active) {
      background-color: @Gray10 !important;
    }
  }

  &.toggle-button {
    background: #fef5e7;
    color: #574f4f;
    border: 1px solid #8a7d7d;
    border-radius: 4px;
    font-size: @font-size-base;
    padding: 0.5rem 1.3rem;

    &.selected {
      font-weight: bold;
      border-color: #597b5a;
      box-shadow: 0 0 2px 1px #c1cebd;
    }

    &:not(:disabled):hover {
      background-color: #f2ebe0;
    }

    &:not(:disabled):active {
      box-shadow: 0 0 2px 1px #c1cebd;
      border-color: #574f4f;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &.wrap {
    @media screen and (max-width: @screen-sm) {
      white-space: normal;
    }
  }

  &.thule-btn-classic-link {
    padding: 0;
    min-height: 0;
    min-width: 0;
    text-decoration: underline;
  }
}
