////////////////////////////////////////////////////////////////////////////////////
// FONTS
////////////////////////////////////////////////////////////////////////////////////

@baseFontName: 'SuisseIntl-';
@fontPath: './fonts';
.createFontFace(@fontName, @weight) {
  @font-face {
    font-family: 'suisse';
    src:
      url('@{fontPath}/@{baseFontName}@{fontName}-WebM.woff2') format('woff2'),
      url('@{fontPath}/@{baseFontName}@{fontName}-WebM.woff') format('woff');
    font-weight: @weight;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'suisse';
    src:
      url('@{fontPath}/@{baseFontName}@{fontName}Italic-WebM.woff2') format('woff2'),
      url('@{fontPath}/@{baseFontName}@{fontName}Italic-WebM.woff') format('woff');
    font-weight: @weight;
    font-style: italic;
    font-display: swap;
  }
}

.createFontFace('Ultralight', 250);
.createFontFace('Thin', 275);
.createFontFace('Light', 300);
.createFontFace('Regular', 400);
.createFontFace('Book', 500);
.createFontFace('Medium', 600);
.createFontFace('SemiBold', 700);
.createFontFace('Bold', 800);
.createFontFace('Black', 900);

.createSuisseWorksFont(@fontName, @weight) {
  @font-face {
    font-family: 'suisseWorks';
    src: url('@{fontPath}/SuisseWorks/SuisseWorks-@{fontName}-WebXL.woff2') format('woff2');
    font-weight: @weight;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'suisseWorks';
    src: url('@{fontPath}/SuisseWorks/SuisseWorks-@{fontName}Italic-WebXL.woff2') format('woff2');
    font-weight: @weight;
    font-style: italic;
    font-display: swap;
  }
}

.createSuisseWorksFont('Regular', 400);
.createSuisseWorksFont('Book', 500);
.createSuisseWorksFont('Medium', 600);
.createSuisseWorksFont('Bold', 800);
////////////////////////////////////////////////////////////////////////////////////
// HEADINGS
////////////////////////////////////////////////////////////////////////////////////

// Reset headings
h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-word;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  hyphens: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading1,
.heading2,
.heading3,
.heading4,
.heading5,
.heading6,
.heading7,
.display1,
.display2,
.display3,
.underline .heading-textsize,
.mega-heading {
  font-weight: 500;
  line-height: 1.5;

  p {
    font-size: inherit;
    color: inherit;
    margin-top: 0;
    margin-bottom: 0;
  }
}

//1 REM = 10PX
.display1 {
  font-size: 4.8rem;

  @media (min-width: @screen-lg-min) {
    font-size: 8rem;
  }
}

.display2 {
  font-size: 4rem;

  @media (min-width: @screen-lg-min) {
    font-size: 6.4rem;
  }
}

.display3 {
  font-size: 3.6rem;

  @media (min-width: @screen-lg-min) {
    font-size: 5.2rem;
  }
}

h1,
.heading1 {
  font-size: 3.2rem;

  @media (min-width: @screen-lg-min) {
    font-size: 4.8rem;
  }
}

h2,
.heading2 {
  font-size: 2.8rem;

  @media (min-width: @screen-lg-min) {
    font-size: 4rem;
  }
}

h3,
.heading3 {
  font-size: 2.4rem;

  @media (min-width: @screen-lg-min) {
    font-size: 3.2rem;
  }
}

h4,
.heading4 {
  font-size: 2rem;

  @media (min-width: @screen-lg-min) {
    font-size: 2.4rem;
  }
}

h5,
.heading5 {
  font-size: 1.6rem;
  font-weight: 600;
}

h6,
.heading6 {
  font-size: 1.4rem;
  font-weight: 600;
}

.heading7 {
  font-size: 1.4rem;

  @media (min-width: @screen-lg-min) {
    font-size: 1.6rem;
  }
}

.underline {
  font-size: 1.6rem;
}

p,
.paragraph--lead,
.paragraph--s,
.paragraph--xs,
.paragraph--7,
.paragraph {
  font-weight: 400;
}

.paragraph--lead {
  font-size: 2rem;
}

p,
.paragraph {
  font-size: 1.6rem;
}

.paragraph--s {
  font-size: 1.4rem;
}

.paragraph--xs {
  font-size: 1.2rem;
}

.paragraph--7 {
  font-size: 1.4rem;

  @media (min-width: @screen-lg-min) {
    font-size: 1.6rem;
  }
}

.heading-textsize {
  font-size: 1.4rem; // 14px
}

.mega-heading {
  font-size: 3.2rem; // ~32px

  @media (min-width: @screen-lg-min) {
    font-size: 6rem; // ~60px
  }
}

.small-text {
  font-size: 1.2rem; // ~12px

  p {
    font-size: inherit;
    margin-bottom: 0;
  }
}

////////////////////////////////////////////////////////////////////////////////////
// GLOBAL TEXT & LINK STYLING
////////////////////////////////////////////////////////////////////////////////////

p {
  font-size: 1.6rem;
  color: @ThuleFontColor;

  a {
    text-decoration: underline;
  }
}

a {
  text-decoration: none;
  color: @CTAPrimary;

  &:hover {
    text-decoration: none;
    color: darken(@CTAPrimary, 15%);
  }
}

.image-text {
  // Text beneath images
  font-size: 1.3rem; // 13px

  @media (min-width: @screen-lg-min) {
    font-size: 1.4rem; // 14px
  }
}

.darker-link {
  // Links on grey backgrounds
  color: @CTAOnBackground;

  &:hover,
  &:focus {
    color: darken(@CTAOnBackground, 15%);
  }
}

.black-link {
  color: black;

  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 0.77);
  }
}

.thuledarkgrey-link {
  color: @Standard;

  &:hover,
  &:focus {
    color: #3e3e3e;
  }
}

.line-through-text {
  text-decoration: line-through;
}

.text--truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

////////////////////////////////////////////////////////////////////////////////////
// FONTS
////////////////////////////////////////////////////////////////////////////////////

.suisseWorks {
  font-family: 'SuisseWorks';
}
