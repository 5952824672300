.input-item.global {
  display: flex;
  flex-direction: row;
  flex: 1;

  &.error label input {
    border: .1rem solid #d40303;
    background: rgba(255, 0, 0, 0.1);
  }

  .control-label {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  label input:disabled {
    background-color: rgb(235, 235, 228);
  }

  label.error {
    color: #d40303;
  }
}
.box-shadow--red{
  box-shadow: 0 0 8px 3px red;
}