@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.cloudflareVideo {
    iframe {
        width: 100%;
    }

    .playButton {
        position: absolute;
        top: 0;
        z-index: 2;
        
        &.hide {
            visibility: hidden;
        }

        .playIcon {
            background-color: #fff;
            border-radius: 5.6rem;
            filter: drop-shadow(0 4px 16px rgba(0, 0, 0, .1));
            height: 5.6rem;
            pointer-events: none;
            width: 5.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
        }
    }
}