.alert-message {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:1rem;
    &.error {
        color: black;
        border-radius: 0;
        border: 1px #d40303 solid;
        background-color: rgba(255, 0, 0, 0.1);
    }

    &.warning {
        color: #8a6d3b;
        background-color: #fcf8e3;
        border-color: #faebcc;
    }

    &.success {
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;
    }

    border: 1px solid transparent;
    border-radius: 4px;

    &.fadeout {
        animation: fadeout 1s forwards;
    }

    .msg-close {
        cursor: pointer;
    }

    @keyframes fadeout {
        0% {
            max-height: 1200px;
            font-size: 1em;
            line-height: "100%";
        }
    
        100% {
            max-height: 0;
            opacity: 0;
            border-width: 0;
            font-size: 1em;
            line-height: "100%";
        }
    }
}