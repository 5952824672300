@frgFontPath: './fonts';
@frgLastModifiedDate: ''; // Cache busting feature. URL Rewrite rule takes care of this in web.config (must be in format ".yyyymmdd")
// This is is not automaticly changed, every time the font files are changed this date must be updated

@font-face {
  font-family: 'frg-icons';
  // Every time the font files are changed @frgLastModifiedDate must be updated
  src: url('@{frgFontPath}/frg-icons@{frgLastModifiedDate}.ttf') format('truetype'),
    url('@{frgFontPath}/frg-icons@{frgLastModifiedDate}.woff') format('woff'),
    url('@{frgFontPath}/frg-icons@{frgLastModifiedDate}.svg#frg-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='frg-icon-'],
[class*=' frg-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'frg-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.frg-icon-Bar-Profiles-Thule-WingBar-Evo:before {
  content: '\e900';
}
.frg-icon-backpack-bring-my-kid:before {
  content: '\e600';
}
.frg-icon-backpack-day-hike:before {
  content: '\e601';
}
.frg-icon-backpack-extended-trip:before {
  content: '\e602';
}
.frg-icon-backpack-over-night:before {
  content: '\e603';
}
.frg-icon-basket:before {
  content: '\e604';
}
.frg-icon-bike:before {
  content: '\e605';
}
.frg-icon-bike-fittingpoint-fork:before {
  content: '\e606';
}
.frg-icon-bike-fittingpoint-frame:before {
  content: '\e607';
}
.frg-icon-bike-fittingpoint-wheel:before {
  content: '\e608';
}
.frg-icon-bike-hangon:before {
  content: '\e609';
}
.frg-icon-bike-platform:before {
  content: '\e60a';
}
.frg-icon-bike-tiltable-1:before {
  content: '\e60b';
}
.frg-icon-bike-tiltable-2:before {
  content: '\e60c';
}
.frg-icon-awk-bike:before {
  content: '\e60d';
}
.frg-icon-awk-double:before {
  content: '\e60e';
}
.frg-icon-awk-hike:before {
  content: '\e60f';
}
.frg-icon-awk-jog:before {
  content: '\e610';
}
.frg-icon-awk-single:before {
  content: '\e611';
}
.frg-icon-awk-ski:before {
  content: '\e612';
}
.frg-icon-awk-walk:before {
  content: '\e613';
}
.frg-icon-bike-accessorytype-bag:before {
  content: '\e614';
}
.frg-icon-bike-accessorytype-electronics:before {
  content: '\e615';
}
.frg-icon-bike-aircraft:before {
  content: '\e616';
}
.frg-icon-bike-bag:before {
  content: '\e617';
}
.frg-icon-bike-car:before {
  content: '\e618';
}
.frg-icon-bike-case:before {
  content: '\e619';
}
.frg-icon-bike-commuting:before {
  content: '\e61a';
}
.frg-icon-bike-everyday-urban:before {
  content: '\e61b';
}
.frg-icon-carrier-type-horizontal:before {
  content: '\e61c';
}
.frg-icon-carrier-type-load-assist:before {
  content: '\e61d';
}
.frg-icon-carrier-type-vertical:before {
  content: '\e61e';
}
.frg-icon-cat-bike-bags-racks:before {
  content: '\e620';
}
.frg-icon-cat-bike-carrier:before {
  content: '\e621';
}
.frg-icon-cat-boxes:before {
  content: '\e622';
}
.frg-icon-cat-camera-bags-cases:before {
  content: '\e623';
}
.frg-icon-cat-child-bike-seats:before {
  content: '\e624';
}
.frg-icon-cat-daypack-messengers:before {
  content: '\e625';
}
.frg-icon-cat-laptop-sleeve-cases:before {
  content: '\e626';
}
.frg-icon-cat-motorhomes:before {
  content: '\e628';
}
.frg-icon-cat-multifunctional-child-carriers:before {
  content: '\e629';
}
.frg-icon-cat-phone-tablet-cases:before {
  content: '\e62a';
}
.frg-icon-cat-roof-rack:before {
  content: '\e62b';
}
.frg-icon-cat-snowchains:before {
  content: '\e62c';
}
.frg-icon-cat-sport-transport-bag1:before {
  content: '\e62e';
}
.frg-icon-cat-sport-transport-bag2:before {
  content: '\e62f';
}
.frg-icon-cat-technical-backpacks:before {
  content: '\e630';
}
.frg-icon-cat-water:before {
  content: '\e631';
}
.frg-icon-cat-winter:before {
  content: '\e632';
}
.frg-icon-foldable:before {
  content: '\e633';
}
.frg-icon-gender-female:before {
  content: '\e634';
}
.frg-icon-gender-male:before {
  content: '\e635';
}
.frg-icon-bike-handlebar:before {
  content: '\e636';
}
.frg-icon-hitch-1-25:before {
  content: '\e637';
}
.frg-icon-hitch-2:before {
  content: '\e638';
}
.frg-icon-bike-rack:before {
  content: '\e639';
}
.frg-icon-bike-shipment:before {
  content: '\e63a';
}
.frg-icon-bike-touring:before {
  content: '\e63b';
}
.frg-icon-bike-train:before {
  content: '\e63c';
}
.frg-icon-lockable-bike-to-carrier:before {
  content: '\e63d';
}
.frg-icon-lockable-carrier-to-car:before {
  content: '\e63e';
}
.frg-icon-locks-included:before {
  content: '\e63f';
}
.frg-icon-looks-long:before {
  content: '\e640';
}
.frg-icon-looks-medium:before {
  content: '\e641';
}
.frg-icon-looks-short:before {
  content: '\e642';
}
.frg-icon-mounting-hitch:before {
  content: '\e643';
}
.frg-icon-mounting-rear:before {
  content: '\e644';
}
.frg-icon-mounting-roof:before {
  content: '\e645';
}
.frg-icon-mounting-towbar:before {
  content: '\e646';
}
.frg-icon-mounting-truckbed:before {
  content: '\e647';
}
.frg-icon-number-1:before {
  content: '\e648';
}
.frg-icon-number-2:before {
  content: '\e649';
}
.frg-icon-number-3:before {
  content: '\e64a';
}
.frg-icon-number-4:before {
  content: '\e64b';
}
.frg-icon-number-5:before {
  content: '\e64c';
}
.frg-icon-number-6:before {
  content: '\e64d';
}
.frg-icon-number-7:before {
  content: '\e64e';
}
.frg-icon-profile-aerobar:before {
  content: '\e64f';
}
.frg-icon-profile-probar:before {
  content: '\e650';
}
.frg-icon-profile-slidebar:before {
  content: '\e651';
}
.frg-icon-profile-squarebar:before {
  content: '\e652';
}
.frg-icon-profile-wingbar:before {
  content: '\e653';
}
.frg-icon-roof-box:before {
  content: '\e654';
}
.frg-icon-size-alpine:before {
  content: '\e655';
}
.frg-icon-size-l:before {
  content: '\e656';
}
.frg-icon-size-m:before {
  content: '\e657';
}
.frg-icon-size-s:before {
  content: '\e658';
}
.frg-icon-size-sport:before {
  content: '\e659';
}
.frg-icon-size-xl:before {
  content: '\e65a';
}
.frg-icon-size-xxl:before {
  content: '\e65b';
}
.frg-icon-smartphone:before {
  content: '\e65c';
}
.frg-icon-snow-chain-external:before {
  content: '\e65d';
}
.frg-icon-snow-chain-inovative:before {
  content: '\e65e';
}
.frg-icon-snow-chain-onestopfit:before {
  content: '\e65f';
}
.frg-icon-snow-chain-traditional:before {
  content: '\e660';
}
.frg-icon-tablet-7-9:before {
  content: '\e661';
}
.frg-icon-tablet-9-10:before {
  content: '\e662';
}
.frg-icon-water-canoe:before {
  content: '\e663';
}
.frg-icon-water-kayak:before {
  content: '\e664';
}
.frg-icon-water-sup:before {
  content: '\e665';
}
.frg-icon-water-surf:before {
  content: '\e666';
}
.frg-icon-water-windsurf:before {
  content: '\e667';
}
.frg-icon-winter-alpine:before {
  content: '\e668';
}
.frg-icon-winter-cross-country:before {
  content: '\e669';
}
.frg-icon-winter-snowboard:before {
  content: '\e66a';
}
.frg-icon-camera-action:before {
  content: '\e66b';
}
.frg-icon-camera-backpack:before {
  content: '\e66c';
}
.frg-icon-camera-cable-charger:before {
  content: '\e66d';
}
.frg-icon-camera-case:before {
  content: '\e66e';
}
.frg-icon-camera-csc:before {
  content: '\e66f';
}
.frg-icon-camera-flash:before {
  content: '\e670';
}
.frg-icon-camera-ipad-tablet:before {
  content: '\e671';
}
.frg-icon-camera-laptop:before {
  content: '\e672';
}
.frg-icon-camera-lens:before {
  content: '\e673';
}
.frg-icon-camera-model:before {
  content: '\e674';
}
.frg-icon-camera-pro-dslr:before {
  content: '\e675';
}
.frg-icon-camera-profile:before {
  content: '\e676';
}
.frg-icon-camera-prosumer-dslr:before {
  content: '\e677';
}
.frg-icon-camera-prosumer-dslr-battery:before {
  content: '\e678';
}
.frg-icon-camera-shoulder-bag:before {
  content: '\e679';
}
.frg-icon-camera-sling:before {
  content: '\e67a';
}
.frg-icon-camera-standard-dslr:before {
  content: '\e67b';
}
.frg-icon-camera-standard-dslr-battery:before {
  content: '\e67c';
}
.frg-icon-camera-toploader:before {
  content: '\e67d';
}
.frg-icon-camera-tripod:before {
  content: '\e67e';
}
.frg-icon-dam-daypack:before {
  content: '\e67f';
}
.frg-icon-dam-messenger-bag:before {
  content: '\e680';
}
.frg-icon-dam-sling-pack:before {
  content: '\e681';
}
.frg-icon-lsc-bag:before {
  content: '\e682';
}
.frg-icon-lsc-hard-case:before {
  content: '\e683';
}
.frg-icon-lsc-macbook:before {
  content: '\e684';
}
.frg-icon-lsc-pc:before {
  content: '\e685';
}
.frg-icon-lsc-sleeve:before {
  content: '\e686';
}
.frg-icon-lsc-soft-case:before {
  content: '\e687';
}
.frg-icon-table-cases-bag:before {
  content: '\e688';
}
.frg-icon-table-cases-hard-case:before {
  content: '\e689';
}
.frg-icon-table-cases-sleeve:before {
  content: '\e68a';
}
.frg-icon-table-cases-soft-case:before {
  content: '\e68b';
}
.frg-icon-cat-smartphone:before {
  content: '\e68d';
}
.frg-icon-cat-tablet:before {
  content: '\e68e';
}
.frg-icon-warning:before {
  content: '\e901';
}
.frg-icon-b:before {
  content: '\e902';
}
.frg-icon-bike-backpack:before {
  content: '\e903';
}
.frg-icon-ski-backpack:before {
  content: '\e904';
}
.frg-icon-stroller-glide:before {
  content: '\e905';
}
.frg-icon-stroller:before {
  content: '\e906';
}
.frg-icon-cat-luggage-3:before {
  content: '\e907';
}
.frg-icon-cat-luggage-2:before {
  content: '\e908';
}
.frg-icon-cat-luggage-2-side:before {
  content: '\e909';
}
.frg-icon-cat-bicycle-trailers:before {
  content: '\e90a';
}
.frg-icon-cat-child-bike-seats-back1:before {
  content: '\e90b';
}
.frg-icon-cat-child-bike-seats-back2:before {
  content: '\e90c';
}
.frg-icon-cat-child-bike-seats-front:before {
  content: '\e90d';
}
.frg-icon-e-bike:before {
  content: '\e90e';
}
.frg-icon-child-bike-seats-rack-mounted:before {
  content: '\e90f';
}
.frg-icon-child-bike-seats-frame-mounted:before {
  content: '\e910';
}
.frg-icon-child-bike-seats-16-22kg:before {
  content: '\e911';
}
.frg-icon-child-bike-seats-9-15kg:before {
  content: '\e912';
}
.frg-icon-cat-boxes2:before {
  content: '\e913';
}
.frg-icon-cat-laptop-phone:before {
  content: '\e914';
}
.frg-icon-cat-luggage-duffels:before {
  content: '\e915';
}
.frg-icon-cat-sport-strollers:before {
  content: '\e916';
}
.frg-icon-section-awk:before {
  content: '\e917';
}
.frg-icon-section-caravans:before {
  content: '\e918';
}
.frg-icon-section-carriers-racks:before {
  content: '\e919';
}
.frg-icon-section-luggage:before {
  content: '\e91a';
}
.frg-icon-section-van:before {
  content: '\e91b';
}
.frg-icon-drone:before {
  content: '\e91c';
}
.frg-icon-cat-child-carrier-backpacks:before {
  content: '\e91d';
}
.frg-icon-cart-complete:before {
  content: '\e91e';
}
.frg-icon-cart-error:before {
  content: '\e91f';
}
.frg-icon-cart-notice:before {
  content: '\e920';
}
.frg-icon-shipping-notice:before {
  content: '\e921';
}
.frg-icon-thule-logo-cutout:before {
  content: '\e922';
}
.frg-icon-thule:before {
  content: '\e923';
}
.frg-icon-thule-logo-only-text:before {
  content: '\e924';
}
.frg-icon-Awning:before {
  content: '\e925';
}
.frg-icon-Edit-account:before {
  content: '\e926';
}
.frg-icon-thule_transport_icon200525:before {
  content: '\e927';
}
.frg-icon-Activity_Explore:before {
  content: '\e928';
}
.frg-icon-Activity_Instashop:before {
  content: '\e929';
}
.frg-icon-Activity_News:before {
  content: '\e92a';
}
.frg-icon-Activity_PathosPassion:before {
  content: '\e92b';
}
.frg-icon-Activity_Sustainability:before {
  content: '\e92c';
}
.frg-icon-Activity_ThuleTestCenter:before {
  content: '\e92d';
}
.frg-icon-Activity_TipsStories:before {
  content: '\e92e';
}
.frg-icon-bs:before {
  content: '\e92f';
}
.frg-icon-b-ss:before {
  content: '\e930';
}
.frg-icon-cc:before {
  content: '\e931';
}
.frg-icon-cs:before {
  content: '\e932';
}
.frg-icon-c-ss:before {
  content: '\e933';
}
.frg-icon-kids:before {
  content: '\e934';
}
.frg-icon-sc:before {
  content: '\e935';
}
.frg-icon-ss:before {
  content: '\e936';
}
.frg-icon-180:before {
  content: '\e937';
}
.frg-icon-L:before {
  content: '\e938';
}
.frg-icon-R:before {
  content: '\e939';
}
.frg-icon-360:before {
  content: '\e93a';
}
.frg-icon-b2:before {
  content: '\e93b';
}
.frg-icon-c:before {
  content: '\e93c';
}
.frg-icon-kid:before {
  content: '\e93d';
}
.frg-icon-s:before {
  content: '\e93e';
}
.frg-icon-stroller-BG:before {
  content: '\e93f';
}
.frg-icon-bc:before {
  content: '\e940';
}
.frg-icon-as:before {
  content: '\e941';
}
.frg-icon-360arrow:before {
  content: '\e942';
}
.frg-icon-hydration-pack-water:before {
  content: '\e943';
}
.frg-icon-lug1:before {
  content: '\e944';
}
.frg-icon-lug2:before {
  content: '\e945';
}
.frg-icon-lug3:before {
  content: '\e946';
}
.frg-icon-lug4:before {
  content: '\e947';
}
.frg-icon-section-toptent:before {
  content: '\e948';
}
.frg-icon-section-RV-awning:before {
  content: '\e949';
}
.frg-icon-lock-system:before {
  content: '\e94a';
}
.frg-icon-Chrash-nose:before {
  content: '\e94b';
}
.frg-icon-DualSide-opening:before {
  content: '\e94c';
}
.frg-icon-FastClick:before {
  content: '\e94d';
}
.frg-icon-Arrow:before {
  content: '\e94e';
}
.frg-icon-Load-net:before {
  content: '\e94f';
}
.frg-icon-Outside-Handle-bar:before {
  content: '\e950';
}
.frg-icon-PowerClick:before {
  content: '\e951';
}
.frg-icon-Syncronizedl-id-lifters:before {
  content: '\e952';
}
.frg-icon-Plus:before {
  content: '\e953';
}
.frg-icon-Central-lock-system:before {
  content: '\e954';
}
.frg-icon-DualSide-opening1:before {
  content: '\e955';
}
.frg-icon-FastClick1:before {
  content: '\e956';
}
.frg-icon-Handlebar:before {
  content: '\e957';
}
.frg-icon-Interior:before {
  content: '\e958';
}
.frg-icon-Light:before {
  content: '\e959';
}
.frg-icon-LockKnob:before {
  content: '\e95a';
}
.frg-icon-PowerClick1:before {
  content: '\e95b';
}
.frg-icon-SlideLock-system:before {
  content: '\e95c';
}
.frg-icon-FullTrunk-opening:before {
  content: '\e95d';
}
.frg-icon-youtube:before {
  content: '\e95e';
}
.frg-icon-quote:before {
  content: '\e95f';
}
.frg-icon-Arrow-long:before {
  content: '\e960';
}
.frg-icon-Out-of-stock-Triangle:before {
  content: '\e961';
}
.frg-icon-Thule-Glide-Stroller-Car-seat:before {
  content: '\e962';
}
.frg-icon-Thule-Glide-Stroller:before {
  content: '\e963';
}
.frg-icon-Thule-Urban-Glide-Double-Stroller-Basinett:before {
  content: '\e964';
}
.frg-icon-Thule-Urban-Glide-Double-Stroller-Car-seat:before {
  content: '\e965';
}
.frg-icon-Thule-Urban-Glide-Double-Stroller:before {
  content: '\e966';
}
.frg-icon-Thule-Urban-Glide-Stroller-Basinett:before {
  content: '\e967';
}
.frg-icon-Thule-Urban-Glide-Stroller-Car-seat:before {
  content: '\e968';
}
.frg-icon-Thule-Urban-Glide-Stroller:before {
  content: '\e969';
}
.frg-icon-flyfishing:before {
  content: '\e96a';
}
.frg-icon-Activity_Skiing:before {
  content: '\e96b';
}
.frg-icon-Backpacks_Icon_2020:before {
  content: '\e96c';
}
.frg-icon-Bike_Icon_2020:before {
  content: '\e96d';
}
.frg-icon-Bike_Packs_Bags__Racks_Icon_2020:before {
  content: '\e96e';
}
.frg-icon-Bike_Racks_Icon_2020:before {
  content: '\e96f';
}
.frg-icon-City_Adventure_Icon_2021:before {
  content: '\e970';
}
.frg-icon-Activity_Bike:before {
  content: '\e971';
}
.frg-icon-Activity_Camp:before {
  content: '\e972';
}
.frg-icon-Activity_Family:before {
  content: '\e973';
}
.frg-icon-Activity_FriendsofThule:before {
  content: '\e974';
}
.frg-icon-Marker:before {
  content: '\e975';
}
.frg-icon-Activity_Hike:before {
  content: '\e976';
}
.frg-icon-Activity_History:before {
  content: '\e977';
}
.frg-icon-Activity_OutdoorAdventure:before {
  content: '\e978';
}
.frg-icon-Activity_ThisisThule:before {
  content: '\e979';
}
.frg-icon-Activity_Travel:before {
  content: '\e97a';
}
.frg-icon-Car-sedan:before {
  content: '\e97b';
}
.frg-icon-Check:before {
  content: '\e97c';
}
.frg-icon-Cross:before {
  content: '\e97d';
}
.frg-icon-Extra-information:before {
  content: '\e97e';
}
.frg-icon-Filter:before {
  content: '\e97f';
}
.frg-icon-Bike_Trailers_Icon_2020:before {
  content: '\e980';
}
.frg-icon-Bring_Your_Life_Icon_2020:before {
  content: '\e981';
}
.frg-icon-Caravan_Accessories_Icon_2020:before {
  content: '\e982';
}
.frg-icon-Cargo_Carriers_and_Baskets_Icon_2020:before {
  content: '\e983';
}
.frg-icon-Minus:before {
  content: '\e984';
}
.frg-icon-My-account:before {
  content: '\e985';
}
.frg-icon-Plus-1:before {
  content: '\e986';
}
.frg-icon-Plus1:before {
  content: '\e987';
}
.frg-icon-Shipping-warning:before {
  content: '\e988';
}
.frg-icon-Child_Bike_Seats_Icon_2020:before {
  content: '\e989';
}
.frg-icon-hydration-pack:before {
  content: '\e98a';
}
.frg-icon-Child_Carrier_Backpacks_Icon_2020:before {
  content: '\e98b';
}
.frg-icon-Explore_Icon_2020:before {
  content: '\e98c';
}
.frg-icon-arrow-long-Down:before {
  content: '\e98d';
}
.frg-icon-arrow-long-Left:before {
  content: '\e98e';
}
.frg-icon-Arrows-up-and-down:before {
  content: '\e98f';
}
.frg-icon-bell-regular:before {
  content: '\e990';
}
.frg-icon-Calendar:before {
  content: '\e991';
}
.frg-icon-Hike_Trek_Icon_2020:before {
  content: '\e992';
}
.frg-icon-Instashop_Icon_2020:before {
  content: '\e993';
}
.frg-icon-Laptop_Cases_Sleeves__Cases_Icon_2020:before {
  content: '\e994';
}
.frg-icon-Luggage_Icon_2020:before {
  content: '\e995';
}
.frg-icon-Motorhome_Accessories_Icon_2020:before {
  content: '\e996';
}
.frg-icon-Car-front:before {
  content: '\e997';
}
.frg-icon-Chat:before {
  content: '\e998';
}
.frg-icon-Export:before {
  content: '\e999';
}
.frg-icon-Eye-slash-menu:before {
  content: '\e99a';
}
.frg-icon-Fast-truck:before {
  content: '\e99b';
}
.frg-icon-minus-1:before {
  content: '\e99c';
}
.frg-icon-News_Icon_2020:before {
  content: '\e99d';
}
.frg-icon-Order-history:before {
  content: '\e99e';
}
.frg-icon-plus-1:before {
  content: '\e99f';
}
.frg-icon-Preseason:before {
  content: '\e9a0';
}
.frg-icon-Shipping:before {
  content: '\e9a1';
}
.frg-icon-Thule-group-Cart:before {
  content: '\e9a2';
}
.frg-icon-Thule-group-Filter:before {
  content: '\e9a3';
}
.frg-icon-Our_History_Icon_2020:before {
  content: '\e9a4';
}
.frg-icon-Pathos__Passion_Icon_2020:before {
  content: '\e9a5';
}
.frg-icon-Roof_Racks_Icon_2020:before {
  content: '\e9a6';
}
.frg-icon-Thule-Group-Logo:before {
  content: '\e9a7';
}
.frg-icon-Thule-group-My-Account:before {
  content: '\e9a8';
}
.frg-icon-Car-icon:before {
  content: '\e9a9';
}
.frg-icon-Filter-icon:before {
  content: '\e9aa';
}
.frg-icon-Fit-or-no-fit:before {
  content: '\e9ab';
}
.frg-icon-Information-icon:before {
  content: '\e9ac';
}
.frg-icon-Roof_Top_Tent_and_Accessories_Icon_2020:before {
  content: '\e9ad';
}
.frg-icon-Sport_Travel_Bags_and_Cases_Icon_2020:before {
  content: '\e9ae';
}
.frg-icon-Strollers_Icon_2020:before {
  content: '\e9af';
}
.frg-icon-Sustainability_Icon_2020:before {
  content: '\e9b0';
}
.frg-icon-Thule_Crew_Icon_2020:before {
  content: '\e9b1';
}
.frg-icon-Thule_Text_Center_Icon_2020:before {
  content: '\e9b2';
}
.frg-icon-Bar-Profiles-Thule-ProBar:before {
  content: '\e9b3';
}
.frg-icon-Bar-Profiles-Thule-SlideBar:before {
  content: '\e9b4';
}
.frg-icon-Bar-Profiles-Thule-SquareBar:before {
  content: '\e9b5';
}
.frg-icon-Bar-Profiles-Thule-WingBar:before {
  content: '\e9b6';
}
.frg-icon-Tips_Stories_Icon_2020:before {
  content: '\e9b9';
}
.frg-icon-Travel_Icon_2020:before {
  content: '\e9bb';
}
.frg-icon-Van_Accessories_Icon_2020:before {
  content: '\e9bc';
}
.frg-icon-Van_Life_Icon_2020:before {
  content: '\e9bd';
}
.frg-icon-Water_Sport_Icon_2020:before {
  content: '\e9c4';
}
.frg-icon-Winter__Water_Sports_Racks_Icon_2020:before {
  content: '\e9c5';
}
.frg-icon-Winter_Sport_Icon_2020:before {
  content: '\e9cd';
}
