footer {
  color: white;
  box-shadow: 0 50vh 0 50vh @Gray90;
  position: relative;

  .layout__content {
    background-color: inherit;

    .footer-links {
      @media screen and (min-width: @screen-lg) {
        width: 180px;

        &:first-child {
          margin-left: 0 !important;
        }
      }

      .footer-links-item {
        border-bottom: 0.1rem @Gray20 solid;
        font-size: 1.6rem;

        .paragraph-lead {
          color: white;
        }

        ul li {
          list-style-type: none;

          a {
            color: @Gray40;
          }
        }

        button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          i {
            background-color: @White;

            &.le-icon-minus {
              display: none;
            }
          }

          &[aria-expanded='true'] {
            > i {
              &.le-icon-minus {
                display: block;
              }

              &.le-icon-plus {
                display: none;
              }
            }
          }
        }

        @media screen and (min-width: @screen-lg) {
          border-bottom: none;

          .footer-links-item__links.collapse {
            display: block;
          }
        }
      }
    }

    .footer-social-links {
      @media screen and (min-width: @screen-lg) {
        display: flex;
        justify-content: end;
      }

      .footer-social-links__icon {
        font-size: 2rem;
        color: @Gray20;
      }

      .footer-social-links__icon:not(:first-child) {
        margin-left: 2rem;
      }
    }

    .payment-method-logos-container {
      p {
        color: @Gray40;
      }

      .payment-method-logos {
        flex-wrap: wrap;
        column-gap: @spacer4;
        row-gap: @spacer5;

        img {
          max-height: @spacer6;
        }
      }
    }

    hr {
      color: @Gray60;
    }

    .bottomRow {
      display: flex;
      justify-content: space-between;
      align-items: center;

      * {
        color: @Gray40;
      }

      i {
        background-color: @Gray40;
      }

      .footer-market-selector__btn {
        display: flex;
        align-items: center;
        color: @Gray40;
      }
    }

    .bottom-links {
      @media screen and (max-width: @screen-md-max) {
        display: flex;
        flex-direction: column;

        a,
        .cookie-settings-link {
          color: @Gray40;

          &:not(:last-child) {
            margin-bottom: @spacer6;
          }
        }
      }
    }
  }
}
