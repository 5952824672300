.color-selector-container {
    .color-selector {
        ul {
            font-size: 0;
            margin-top: -@spacer3;
        }

        li {
            display: inline-block;
            height: 20px;
            width: 20px;

            @media (max-width: @screen-md-max) {
                margin-right: @spacer3;
            }

            @media (min-width: @screen-lg-min) {
                height: 40px;
                width: 44px;
            }
        }

        a {
            display: inline-block;
            height: 20px;
            width: 20px;
            border: 1px solid @White;
            background-size: cover;
            image-rendering: pixelated;
            background-repeat: no-repeat;

            &.active {
                box-shadow: 0 0 0 1px @Black;
            }

            @media (min-width: @screen-lg-min) {
                height: 40px;
                width: 40px;
                border: 4px solid @White;
            }
        }
    }

    .selected-swatch-name {
        color: @Gray80;
    }
}

.space-below-small {
    margin-bottom: @spacer12;

    @media (min-width: @screen-lg-min) {
        margin-bottom: @spacer16;
    }
}

.space-below {
    margin-bottom: @spacer12;
}

.new-flag,
.coming-soon-flag {
    z-index: 1;
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0.6rem;
    font-size: 1.2rem;
    line-height: 1;
    background-color: white;
    color: @Gray80;
}

.new-flag {
    &::after {
        content: 'New';
    }
}

.coming-soon-flag {
    &::after {
        content: 'Coming soon';
    }
}

.caselogic-site,
.extranet-site {
    .coming-soon-flag,
    .new-flag {
        background-color: @Gray10;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fa-spin {
    animation: spin 1.5s linear infinite;
}

.klarna-loader {
    display: flex;
    margin: 5em auto 5em auto;
    justify-content: center;
}

.ytPlayBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .ytPlayIcon {
        height: 5.6rem;
        width: 5.6rem;
        align-items: center;
        justify-content: center;
        border-radius: 5.6rem;
        display: flex;
        background-color: @White;
        pointer-events: none;
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
    }
}

.fit-indicator {
    &.rackmounting,
    &.confirmed {
        background-color: @Success100;
    }

    &.noselection {
        background-color: @Black;
    }

    &.unconfirmed {
        background-color: @Gray80;
    }

    &.nofit {
        background-color: @Error100;
    }
}

.horizontalLine {
    border-bottom: 0.1rem @Gray20 solid;
    width: 100%;
}

.aspect-ratio-1x1{
    aspect-ratio: 1 / 1;
}

i.logotype {
    line-height: 0;
    font-size: @LogoTypeMobileSize;

    @media screen and (min-width: @screen-lg) {
      font-size: @LogoTypeDesktopSize;
    }

    &:extend([class^='frg-icon-'],
    [class*=' frg-icon-'] all);
    &:extend(.frg-icon-thule-logo-only-text all);
  }