@import '@{variablesLocation}';
@import '/Foundation/Styling/code/thule/global/media-queries.less';

.imageSlider {
    position: relative;

    .thumbnailCarousel {
        display: none;
        margin-top: @spacer3;
    }

    &.imageSlider--thumbnailCarousel {
        .wrapper {
            border: none;

            // .splide--main .splide__slide {
            //     background-color: @Gray10;
            // }

            .progress-container {
                bottom: 1rem;
                padding-inline: var(--sitePadding-inline);
            }
        }

        .splide--thumbnail {
            .splide__track {
                // margin-left: var(--sitePadding-inline);
            }
            // .splide__slide{
            //     background-color: @Gray10;
            // }
            .splide__track--nav > .splide__list > .splide__slide.is-active {
                border: .2rem solid @PitchBlack;
            }

            img {
                aspect-ratio: 1 / 1;
                object-fit: cover;
            }
        }

        .thumbnailCarousel {
            display: block;
        }

        .splide__arrows,
        .zoomText {
            display: none;
        }

        .imageSlider__count {
            bottom: 2.5rem;
        }
    }

    .wrapper {
        border: .1rem solid @Gray20;
        position: relative;

        .progress-container {
            position: absolute;
            overflow: hidden;
            width: 100%;
            left: 0;
            bottom: 0;

            .progress-bar {
                background-color: @Gray10;
                height: 4px;
                position: relative;
                width: 100%;

                .progress-fill {
                    width: 100%;
                    background-color: @Gray80;
                    height: 4px;
                    position: absolute;
                }
            }
        }

        .splide {
            .splide__slide {
                cursor: pointer;

                img {
                    aspect-ratio: 1 / 1;
                    object-fit: cover;
                    width: 100%;
                }
            }

            .splide__arrows {
                position: absolute;
                bottom: 2.6rem;
                right: 0;

                .splide__arrow {
                    border-radius: 0;
                    background: @Gray10;
                }

                .splide__arrow--prev {
                    left: -6.4rem;
                }

                .splide__arrow--next {
                    right: 0;
                }
            }
        }
    }

    .imageSlider__zoom {
        background-color: #fff;
        height: 100vh;
        left: 0;
        overflow: scroll;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 1100;

        @media screen and (max-width: @screen-md-max) {
            scrollbar-width: none;

            img {
                max-width: unset;
                width: 200vw;
            }
        }

        .zoom__close {
            justify-content: center;
            right: 7rem;
            top: 6.4rem;
            width: 3.6rem;
            position: fixed;
        }
    }

    .zoomText {
        position: absolute;
        top: @spacer6;
        right: 0;
        color: @Gray80;
    }

    .imageSlider__count {
        background-color: @Gray10;
        position: absolute;
        bottom: 1.2rem;
        padding: @spacer2;
    }
}