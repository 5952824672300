.layout {
  &__header {
    position: relative;
  }

  &__content {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    min-height: 10vh;

    img {
      max-width: 100%;
      display: block;
      height: auto;
      border: 0 none;
    }
  }
}
