* {
  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @ThuleGroupBlack;
    border-radius: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    background-color: @ThuleGroupLightGrey;
  }
}

.scrollBar--0 {
  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollBar--1 {
  &::-webkit-scrollbar {
    height: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @Gray80;
    border-radius: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.scrollBar--2 {
  &::-webkit-scrollbar {
    height: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @Gray30;
    border-radius: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    background-color: @Gray10;
  }
}

