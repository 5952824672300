/// <reference path="thule.less" />

// General
// --------------------------------------------------

html {
  font-size: 62.5%; // Font base size is set to 62.5% => 10px in order to facilitate rem calculations. 1.4rem = 14px, 2rem = 20px etc.
  min-width: 320px;
}

body {
  font-family: @ThuleFontFamily;
  font-size: 1.4rem;
  color: @Black;
  line-height: 1.5;
  letter-spacing: normal;

  &[class*='viewMode_'] {
    overflow: hidden;
    -webkit-transform-origin-x: 0;
    -webkit-transform-origin-y: 0;
    width: @cataloguePageWidth;
    height: @cataloguePageHeight;
  }

  &.viewMode_baseTemplate {
    transform: scale(0.127);
    width: 1440px;
    height: 810px;
    transform-origin: top left;
  }

  &.viewMode_thumbnail {
    transform: scale(0.2);
    transform-origin: top left;
  }

  &.viewMode_previewMain {
    transform: scale(0.8);
    transform-origin: top left;
  }
}

hr {
  margin: @grid-gutter-width 0;
}

.thule-custom-dropdown {
  button {
    text-align: left;
    padding-left: 0.8rem;
  }

  li {
    cursor: default;
  }
}
