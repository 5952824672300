.notification__inline,
.dropship-restriction {
  border-width: 0.1rem;
  border-style: solid;
}

.notification__toast {
  position: fixed;
  right: @spacer9;
  bottom: @spacer8;
  width: 381px;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;

  @media (max-width: @screen-md-max) {
    width: calc(100% - @spacer6*2);
    right: @spacer6;
  }

  @keyframes slide-in {
    100% {
      transform: translateX(0%);
    }
  }
}

.notification__inline,
.notification__toast,
.dropship-restriction {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .notification__message,
  .notification__title {
    &.disabled {
      text-decoration: line-through;
    }
  }

  &.size--sm {
    padding: @spacer3 @spacer4;

    * {
      font-size: 12px;
    }

    .show-icon {
      .notification__message,
      .notification__title {
        padding-left: @spacer4;
      }
    }

    &.neutral {
      .icon:first-of-type {
        height: 20px;
        width: 20px;

        & > .le-icon-bg {
          height: 20px;
          width: 20px;
        }

        & > :not(.le-icon-bg) {
          height: 20px;
          width: 20px;
        }
      }
    }

    &.information {
      .icon:first-of-type {
        height: 20px;
        width: 20px;

        & > .le-icon-bg {
          height: 20px;
          width: 20px;
        }

        & > :not(.le-icon-bg) {
          height: 20px;
          width: 20px;
        }
      }
    }

    &.success {
      .icon:first-of-type {
        height: 20px;
        width: 20px;

        & > .le-icon-bg {
          height: 20px;
          width: 20px;
        }

        & > :not(.le-icon-bg) {
          height: 20px;
          width: 20px;
          scale: 0.9;
        }
      }
    }

    &.warning {
      .icon:first-of-type {
        height: 20px;
        width: 20px;

        & > .le-icon-bg {
          height: 20px;
          width: 20px;
        }

        & > :not(.le-icon-bg) {
          height: 20px;
          width: 20px;
        }
      }
    }

    &.error {
      .icon:first-of-type {
        height: 20px;
        width: 20px;

        & > .le-icon-bg {
          height: 20px;
          width: 20px;
        }

        & > :not(.le-icon-bg) {
          height: 20px;
          width: 20px;
        }
      }
    }

    .notification__close {
      width: 20px;
      height: 20px;

      i {
        width: 20px;
        height: 20px;
        scale: 0.9;
      }
    }

    .spinner {
      width: 20px;
      height: 20px;
    }
  }

  &.size--lg {
    padding: @spacer6;

    .notification__message,
    .notification__title {
      line-height: 24px;
    }

    .show-icon {
      .notification__message,
      .notification__title {
        padding-left: @spacer5;
      }
    }
  }

  .icon {
    height: @spacer7;

    &:first-of-type {
      position: relative;

      & > :not(.le-icon-bg) {
        position: absolute;
        left: 0;
      }
    }
  }

  &.neutral {
    background-color: @Gray10;
    border-color: @Gray20;

    .icon:first-of-type {
      & > .le-icon-bg {
        background: @Gray80;
      }

      & > :not(.le-icon-bg) {
        background: @Gray10;
      }
    }
  }

  &.information {
    background-color: @Selection10;
    border-color: @Selection50;

    .icon:first-of-type {
      & > .le-icon-bg {
        background: @Function100;
      }

      & > :not(.le-icon-bg) {
        background: @Selection10;
      }
    }
  }

  &.success {
    background-color: @Success10;
    border-color: @Success50;

    .icon:first-of-type {
      & > .le-icon-bg {
        background: @Success10;
      }

      & > :not(.le-icon-bg) {
        background: @Success100;
      }
    }
  }

  &.warning {
    background-color: @Warning10;
    border-color: @Warning50;

    i.notification__iconType {
      background-color: @Warning100;
    }
  }

  &.error {
    background-color: @Error10;
    border-color: @Error50;

    .icon:first-of-type {
      & > .le-icon-bg {
        background: @Error100;
      }

      & > :not(.le-icon-bg) {
        background: @Error10;
      }
    }
  }

  a {
    text-decoration: underline !important;
  }
}
