@import '@{variablesLocation}';
@import '/Foundation/Styling/code/thule/global/media-queries.less';

.badge--base {
  padding: 0.2rem 0.6rem;
  width: max-content;
  @media screen and (min-width: @screen-md) {
    padding: 0.2rem 0.8rem;
  }
}

.badge--Informative,
.badge--Commercial {
  position: absolute;
  &:extend(.heading6);
  &:extend(.badge--base);
}

.badge--Informative {
  background-color: @White;
  color: @Black;
  border: 0.05rem @Gray20 solid;
}

.badge--Commercial {
  background-color: @Gray80;
  color: @White;
}

.badge--Features {
  color: @Accent4_100;
  &:extend(.paragraph--s);
}
