@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';
@import '/Foundation/Common/code/Views/Foundation/Common/ProgressBar/ProgressBar.less';

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1050;
}

.modalMenu {
  .modalMenu__item {
    padding: 0;
    border-bottom: 0.1rem @Gray20 solid;
    display: flex;
    justify-content: space-between;

    &:active {
      border-color: @Gray20 !important;
    }

    &.selected {
      background-color: rgba(48, 111, 219, 0.1);
      color: @Function100 !important;

      .check {
        display: inline-block !important;
        background-color: @Function100;
      }
    }
  }

  .thule-input {
    border-bottom: 1px solid @Gray20;
    padding-bottom: @spacer5;

    .trailing-icon {
      right: 0;
    }

    .input {
      padding: 0 @spacer9 0 0;
      border: none !important;
      height: 2.6rem;
    }
  }
}

.right-slide-in-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
  transition: transform ease-in-out 0.3s;
  z-index: 1100;
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  max-height: 100vh;
  border-left: 1px solid @Gray20;

  @media screen and (min-width: @screen-lg-min) {
    min-width: 480px;
  }

  &.wrapper-visible {
    transform: translate(-100%, 0);
  }

  .menu-header {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;

    .close-btn {
      min-height: 0;
      padding: 0;

      @media screen and (min-width: @screen-lg) {
        margin-right: -@spacer6;
      }
    }
  }

  .menu-content-section {
    overflow: auto !important;
    padding: @spacer5 0 @spacer6 0;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: @screen-lg-min) {
      padding: @spacer5 0;
    }
  }

  .product-section {
    display: flex;
    flex-wrap: wrap;
  }

  .confirmation {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: @spacer6;

    @media screen and (min-width: @screen-lg-min) {
      padding: @spacer9;
    }

    background-color: @White;
    z-index: 2;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  }
}
