.btn {
  border-radius: 0;
  font-size: 1.4rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border: 0;

  &.btn-sm,
  .btn-group-sm > .btn {
    font-size: 1.2rem;
  }

  &.btn-lg,
  .btn-group-lg > .btn {
    font-size: 1.6rem;
  }

  &.disabled, &:disabled{
    opacity: 1;
  }
}