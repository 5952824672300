@import '@{variablesLocation}';

.thule-input {
  display: block;
  flex: 1 0 0;

  label, legend {
    margin-bottom: 0.4rem;
  }

  .input {
    &[type="password"]::-ms-reveal {
      display: none;
    }

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    width: 100%;
    height: @spacer10;
    padding: @spacer4;
    border: 1px solid @Gray20;
    flex: 1;
    font-size: 1.6rem;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;

    &:focus-visible {
      outline: none;
      outline-offset: unset;
      border: 1px solid @Gray40;
    }
  }

  .leading-icon {
    position: absolute;
    top: calc(50% - 12px);
    left: @spacer4;
  }

  .trailing-icon {
    position: absolute;
    top: calc(50% - 1.2rem);
    right: @spacer4;

    &.error {
      background-color: @Error100;
    }

    &.clear-button {
      cursor: pointer;
      height: 20px;
      width: 20px;

      i {
        scale: 0.8;
      }
    }
  }

  .supporting-text {
    padding: @spacer2 @spacer3;
    color: @Gray80;
    font-size: 1.2rem;
  }

  &.disabled {

    label,
    .supporting-text, legend {
      color: @Gray60;
    }

    .input {
      &:disabled {
        background-color: @Gray10;
        border: 1px solid @Gray30;
      }

      &::placeholder {
        color: @Gray40;
      }
    }
  }

  &.error {
    .input {
      border: 1px solid @Error100;
    }

    .supporting-text {
      color: @Black;
      background-color: @Error10;

      a {
        text-decoration: underline;
      }
    }
  }

  .forgot-password-link {
    color: @Gray60;
    text-decoration: underline;
  }
}