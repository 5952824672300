@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.modalContent {
    padding: @spacer11 @spacer9;
    overflow: auto;

    transform: translateX(0);
    transition: transform 0.3s ease;
    &.wrapper-visible {
        transition: transform 0.3s ease;
    }

    .link a{
        text-decoration: underline;
    }
    .closeBtn {
        position: absolute;
        right: @spacer6;
        top: @spacer6;
    }
}