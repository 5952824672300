@import '@{variablesLocation}';

  .common__progressBar {
    position: relative;
    height: 4px;
    width: 100%;

    .line {
      position: absolute;
      height: 100%;
      background-color: @Gray10;

      &.fill {
        background-color: @Gray80;
      }
    }
  }
