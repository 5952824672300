@import '@{variablesLocation}';

.thuleForm {
  max-height: 100%;
  background-color: @White;
  position: relative;

  .input-row {
    display: flex;
    column-gap: @spacer6;
    flex-wrap: wrap;
    flex-direction: column;

    div {
      flex: 1 0 0;
    }

    &:has(> .state) {
      .city {
        flex: unset;
        width: 100%;
      }
    }
  }

  .thule-input {
    margin-bottom: @spacer5;

    &::placeholder {
      color: @Gray40;
    }
  }

  label {
    color: @Gray80;
  }


}

.thuleForm__checkbox {
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked+.custom-checkbox::after {
    opacity: 1;
  }

  label {
    display: flex;

    .custom-checkbox {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 2px solid @Gray80;
      border-radius: 3px;
      position: relative;
      cursor: pointer;
      pointer-events: none;
      margin-right: @spacer3;
      flex-shrink: 0;
    }

    .custom-checkbox::after {
      content: '';
      display: block;
      width: 6px;
      height: 12px;
      border: solid @Gray80;
      border-width: 0 2px 2px 0;
      position: absolute;
      top: 1px;
      left: 5px;
      transform: rotate(45deg);
      opacity: 0;
    }
  }
}

.thuleForm__radio {
  input[type="radio"] {
    display: none;
  }
  input[type="radio"]:checked+.custom-radio::after {
    opacity: 1;
  }
  .custom-radio {
    display: inline-block;
    width: 22px;
    height: 22px;
    border: 2px solid black;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
  }

  .custom-radio:focus {
    box-shadow: 0 0 3px 3px rgba(21, 156, 228, 0.4);
  }

  .custom-radio::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    width: 20%;
    height: 42%;
    border: solid @Black;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
  }
}