//////////////////////////////////////////////////////////////
// Button help methods
//////////////////////////////////////////////////////////////

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@color; @background; @border; @hoverBG; @hoverColor; @activeBG; @activeColor; @disabledBG; @disabledColor; @disabledBorder) {
  color: @color;
  background-color: @background;
  border: 1px solid @border;

  i {
    background-color: @color;
  }

  &:hover {
    color: @hoverColor !important;
    background-color: @hoverBG !important;
    border: 1px solid @hoverBG !important;

    i {
      background-color: @hoverColor !important;
    }
  }

  &:focus,
  &.focus {
    color: @color;
    border: 2px solid @Information50;
    background-color: @background;
  }

  &:active,
  &.active {
    color: @activeColor !important;
    background-color: @activeBG !important;
    border: 1px solid @activeBG !important;

    i {
      background-color: @activeColor !important;
    }
  }

  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: @disabledColor;
      background-color: @disabledBG;
      border: 1px solid @disabledBorder;

      i {
        background-color: @disabledColor;
      }
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
}

.button-activebackground-lighter(@background) {
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-color: lighten(@background, 10%);
  }
}

.button-activebackground-darker(@background) {
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-color: darken(@background, 12%);
  }
}
